<template>
  <div class="home">
    <div class="index">
      <h1>KAMINO CARD GENERATOR</h1>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {

  }
}
</script>

<style >
.index{
  margin-top: 50%;
}

.index h1{
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
</style>